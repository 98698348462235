import React, { memo } from 'react'
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { string, boolean, object } from 'yup'

import { useMessage } from 'context/MessageContext'
import encodeForm from 'helpers/encodeForm'
import TextField from 'components/molecules/formFields/TextField'
import Checkbox from 'components/molecules/formFields/Checkbox'

interface ContactFormProps {
  successMessage: string
  errorMessage: string
  buttonLabel: string
  disclaimer: string
}

interface FormValues {
  botField?: string
  firstName: string
  surname: string
  email: string
  phone: string
  message: string
  disclaimer: boolean
}

const ContactForm = ({
  successMessage,
  errorMessage,
  buttonLabel,
  disclaimer,
}: ContactFormProps) => {
  const { dispatch } = useMessage()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const formName = 'contact'

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'success',
          message: successMessage,
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'error',
          message: errorMessage,
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  const gridItemWidths = {
    xs: 12,
    lg: 6,
  }

  return (
    <Formik
      initialValues={{
        botField: '',
        firstName: '',
        surname: '',
        email: '',
        phone: '',
        message: '',
        disclaimer: false,
      }}
      validationSchema={object().shape({
        firstName: string().required('Please enter your first name'),
        surname: string().required('Please enter your surname'),
        email: string()
          .email('Please supply a valid email address')
          .required('Email address is Required'),
        message: string().required('Please enter your message'),
        disclaimer: boolean().oneOf([true], 'Please accept our disclaimer'),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        name={formName}
        data-netlify="true"
        data-netlify-honeypot="botField"
        noValidate
      >
        <Box display="none">
          <TextField
            name="botField"
            label="Name"
            textFieldProps={{
              id: 'bot-field',
              type: 'hidden',
            }}
          />
          <input type="hidden" name="form-name" value={formName} />
        </Box>
        <Grid container spacing={3}>
          <Grid item {...gridItemWidths}>
            <TextField
              name="firstName"
              label="First Name"
              variant="solid-dark"
              textFieldProps={{
                id: 'firstName',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <TextField
              name="surname"
              label="Surname"
              variant="solid-dark"
              textFieldProps={{
                id: 'surname',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <TextField
              name="email"
              label="Email Address"
              variant="solid-dark"
              textFieldProps={{
                id: 'email',
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item {...gridItemWidths}>
            <TextField
              name="phone"
              label="Phone"
              variant="solid-dark"
              textFieldProps={{
                id: 'phone',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              label="Your Message"
              variant="solid-dark"
              textFieldProps={{
                id: 'message',
                fullWidth: true,
                required: true,
                multiline: true,
                rows: 6,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox label={disclaimer} name="disclaimer" />
          </Grid>
        </Grid>
        <Box textAlign="right" mt={4}>
          <Button
            variant="contained"
            type="submit"
            fullWidth={smDown ? true : false}
          >
            {buttonLabel}
          </Button>
        </Box>
      </FormikForm>
    </Formik>
  )
}

export default memo(ContactForm)
