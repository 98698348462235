import React, { memo } from 'react'
import { graphql } from 'gatsby'
import {
  Box,
  BoxProps,
  Container,
  Divider,
  Grid,
  Typography,
  styled,
} from '@mui/material'

import {
  ContactPageQuery,
  // @ts-ignore
} from '__generated__/gatsby-types'

import Layout from 'components/organisms/global/Layout'
import TextHeroCurve from 'components/organisms/hero/TextHeroCurve'
import CornerBox from 'components/atoms/CornerBox'
import ContactList from 'components/molecules/ContactList'
import FollowSocialButtons from 'components/molecules/FollowSocialButtons'
import ContactForm from 'components/organisms/forms/ContactForm'

interface Props {
  data: ContactPageQuery
}

interface FollowUsProps extends BoxProps {
  label: string
}

const DividerBox = styled(Box)(({ theme }) => ({
  height: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    borderBottom: 'none',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const boxPadding = {
  px: {
    xs: 0,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  },
  py: {
    xs: 5,
    md: 2,
    lg: 4,
  },
}

const FollowUs = ({ label, ...props }: FollowUsProps) => (
  <Box {...props}>
    <Divider sx={{ my: 4 }} />
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Typography
        variant="smallText"
        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
      >
        {label}
      </Typography>
      <FollowSocialButtons variant="solid-dark" />
    </Box>
  </Box>
)

const ContactPage = ({ data }: Props) => {
  const d = data.datoCmsContactPage
  const upliftAmount = 30

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      forceSolidNav
    >
      <TextHeroCurve
        heading={d.headingNode.childMarkdownRemark.html}
        text={d.heroText}
        keyId={d.originalId}
        upliftAmount={upliftAmount}
      />
      <Box
        bgcolor="lightGrey"
        component="section"
        pb={{ xs: 6, sm: 8, md: 10, lg: 12, xl: 14 }}
      >
        <Container
          maxWidth="xl"
          sx={{
            mt: -upliftAmount,
            overflow: 'hidden',
          }}
        >
          <CornerBox
            bgcolor="common.white"
            breakpointDown="xs"
            position="relative"
            py={{
              xs: 3,
              md: 4,
            }}
            px={{
              xs: 3,
              md: 0,
              lg: 1,
            }}
          >
            <Grid container spacing={0} sx={{ alignItems: 'stretch' }}>
              <Grid item md={6}>
                <DividerBox {...boxPadding}>
                  <Typography variant="h2">{d.contentHeading}</Typography>
                  <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                    {d.contentText}
                  </Typography>
                  <ContactList isContent variant="avatar" />

                  <FollowUs
                    label={d.socialMediaLabel}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </DividerBox>
              </Grid>
              <Grid item md={6}>
                <Box {...boxPadding}>
                  <Typography variant="h2" sx={{ mb: 4 }}>
                    {d.formHeading}
                  </Typography>
                  <ContactForm
                    successMessage={d.successMessage}
                    errorMessage={d.errorMessage}
                    buttonLabel={d.buttonLabel}
                    disclaimer={d.disclaimerNode.childMarkdownRemark.html}
                  />
                </Box>
                <FollowUs
                  label={d.socialMediaLabel}
                  sx={{ display: { xs: 'block', md: 'none' }, mb: 3 }}
                />
              </Grid>
            </Grid>
          </CornerBox>
        </Container>
      </Box>
    </Layout>
  )
}

export default memo(ContactPage)

export const pageQuery = graphql`
  query ContactPageQuery($originalId: String!) {
    datoCmsContactPage(originalId: { eq: $originalId }) {
      addressLocation {
        latitude
        longitude
      }
      addressNode {
        childMarkdownRemark {
          html
        }
      }
      emailAddress
      telephoneNumber
      telephoneNumberUrl
      socialMedia {
        ...socialMediaLink
      }
      disclaimerNode {
        childMarkdownRemark {
          html
        }
      }
      successMessage
      socialMediaLabel
      contentText
      contentHeading
      buttonLabel
      errorMessage
      formHeading
      headingNode {
        childMarkdownRemark {
          html
        }
      }
      heroText
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
