import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { BreakpointDownProps } from 'constants/breakpoint-props'

interface CornerBoxProps extends BoxProps, BreakpointDownProps {
  borderRadiusSize?: 'small'
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'breakpointDown',
})<BreakpointDownProps>(({ breakpointDown, theme }) => ({
  overflow: 'hidden',
  borderRadius: theme?.shape?.borderRadiusLarge,
  ['&.small']: {
    borderRadius: theme?.shape?.borderRadiusSmall,
  },
  [theme.breakpoints.down(breakpointDown)]: {
    borderRadius: 0,
  },
}))

const CornerBox = ({
  breakpointDown,
  borderRadiusSize,
  ...props
}: CornerBoxProps) => {
  return (
    <StyledBox
      breakpointDown={breakpointDown}
      className={borderRadiusSize}
      {...props}
    />
  )
}

export default memo(CornerBox)
